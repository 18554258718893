<template>
  <v-row no-gutters class="py-3">
    <v-col cols="12" sm="5" md="5" class="custom-medium-text text-green"
      >Escolha o melhor plano para você</v-col
    >
    <v-col
      :class="[{ 'text-center': $vuetify.breakpoint.mdAndUp }, 'py-3']"
      align-self="center"
      v-for="plan in localPlans"
      :key="plan.id"
    >
      <v-btn
        class="custom-btn-green rounded-0"
        small
        @click="selectedAPlan(plan.id)"
        :loading="getLoadingPlan(plan.id)"
        :disabled="
          (!!loading && !!planLoading.value) ||
          disabled ||
          isCurrentPlan(plan.id)
        "
      >
        {{ title(plan.classification) }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    plans: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idSelectPlan: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      planLoading: {
        id: null,
        value: false,
      },
    }
  },
  computed: {
    localPlans() {
      let tempList = []
      if (this.freePlan) tempList.push(this.freePlan)
      if (this.basicPlan) tempList.push(this.basicPlan)
      if (this.completePlan) tempList.push(this.completePlan)
      return tempList.length > 0 ? tempList : null
    },
    freePlan() {
      return this.plans.find((plan) => plan.classification === 'free')
    },
    basicPlan() {
      return this.plans.find((plan) => plan.classification === 'basic')
    },
    completePlan() {
      return this.plans.find((plan) => plan.classification === 'complete')
    },
  },
  methods: {
    title(classificationType) {
      switch (classificationType) {
        case 'free':
          return 'Zero'
        case 'basic':
          return 'Básico'
        case 'complete':
          return 'Completo'
      }
    },
    selectedAPlan(id) {
      this.$emit('selectedAPlan', id)
    },
    getLoadingPlan(id) {
      if (!this.loading) return null
      return this.planLoading.id === id && !!this.planLoading.value
    },
    isCurrentPlan(idPlan) {
      return this.idSelectPlan === idPlan
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
