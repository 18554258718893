<template>
  <v-col cols="12" class="ma-0 pa-0 mt-2" id="scroll-comparative-table">
    <div class="title-fixed ma-0 pa-0" v-if="$vuetify.breakpoint.smAndDown">
      <v-row justify="end" style="background-color: white">
        <v-col cols="7" class="pa-0">
          <v-row no-gutters>
            <v-col v-for="type in listOfCoparativeType" :key="type">
              <span class="grey--text text--darken-1 custom-subtitle">
                {{ type | translate }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          <v-divider />
        </v-col>
      </v-row>
    </div>
    <v-row v-for="(item, i) in itemsWithComparative" :key="i" no-gutters>
      <v-col cols="12" class="py-2">
        <span class="custom-body-text font-weight-medium">
          {{ item.title }}
        </span>
      </v-col>

      <v-col cols="12" class="custom-normal-text grey--text text--darken-1">
        <v-row
          v-for="(activity, i) in item.activities"
          :key="i"
          :style="{
            'background-color': i % 2 === 0 ? '#FAFAFA ' : '#E8F5E9',
          }"
          :class="[$vuetify.breakpoint.mdAndUp ? 'py-1' : 'py-3 px-0']"
        >
          <v-col cols="12" sm="5">
            <span class="custom-subtitle">
              {{ activity.name }}
            </span>
            <v-icon
              color="grey"
              v-if="activity.details"
              @click="activity['panelActive'] = !activity['panelActive']"
            >
              {{ !activity['panelActive'] ? 'expand_more' : 'expand_less' }}
            </v-icon>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="3" />

          <v-col
            :class="[
              $vuetify.breakpoint.mdAndUp
                ? 'text-center'
                : 'custom-subtitle  ma-0 py-2 px-0',
            ]"
            v-for="type in listOfCoparativeType"
            :key="type"
            align-self="center"
          >
            <v-icon v-if="activity.rule[type].type === 'isBlocked'">
              remove
            </v-icon>

            <v-icon v-if="activity.rule[type].type === 'unlimited'">
              check
            </v-icon>

            <span v-if="activity.rule[type].type === 'normal'">
              {{ activity.rule[type].value | currencyFromCents }}
            </span>

            <span v-if="activity.rule[type].type === 'fixed'" class="pr-2">
              {{ textLimit(activity.rule[type]) }}
            </span>
          </v-col>

          <v-col cols="12" v-show="activity.panelActive" class="my-0 py-0 px-5">
            <div
              v-for="(detail, index) in activity.details"
              :key="index"
              :class="[
                index !== 0 ? 'mt-1' : '',
                'custom-subtitle-text grey--text text--darken-1',
              ]"
            >
              {{ detail }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="my-2 pa-0"
        v-if="itemsWithComparative.length - 1 !== i"
      >
        <v-divider style="height: 100%; wight: 100%" />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    juridicType: {
      type: String,
      default: 'me',
    },
    plans: {
      type: Array,
      default: () => {
        return []
      },
    },
    activities: {
      type: Object,
      default: () => {
        return {}
      },
    },
    listOfCoparativeType: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      panelActive: null,
    }
  },
  computed: {
    items() {
      return this.$store.state.comparisonPlans[this.juridicType]
    },
    itemsWithComparative() {
      return this.items.map((item) => {
        for (const activity of item.activities) {
          activity.rule = {}
          for (const classificationType of this.listOfCoparativeType) {
            activity.rule[classificationType] = this.getActivityRule(
              classificationType,
              activity
            )
          }
        }
        return item
      })
    },
  },
  methods: {
    getActivityRule(classificationType, activity) {
      let currentActivity = this.getActivityClassificationTypeAndName(
        classificationType,
        activity.name
      )
      if (currentActivity) {
        return {
          type: this.getType(currentActivity),
          limitType: this.limitType(currentActivity.limitType),
          limit: currentActivity.limit,
          value: currentActivity.value,
        }
      }
      return activity.defaultRule[classificationType]
    },
    getActivityClassificationTypeAndName(classificationType, name) {
      let findNameLowerCase = name.toLowerCase()
      return this.activities[classificationType]?.find((activity) => {
        let nameLowerCase = activity.name.toLowerCase()
        if (nameLowerCase.includes(findNameLowerCase)) return activity
      })
    },
    getType(activity) {
      if (activity.isBlocked) return 'isBlocked'
      if (activity.limitDemarcation === 'unlimited') return 'unlimited'
      if (activity.limitDemarcation === 'fixed') return 'fixed'
      if (activity.limitDemarcation === 'not applicable') return 'normal'
    },
    limitType(limitType) {
      return limitType === 'month' ? 'por mês' : 'por ano'
    },
    textLimit(ruleByType) {
      return `Limite de ${ruleByType.limit} ${this.limitType(ruleByType)}`
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@design';

@media only screen and (max-width: 600px) {
  .custom-subtitle {
    font-size: 0.9em;
    line-height: 1px;
  }
  #scroll-comparative-table {
    position: relative;
    .title-fixed {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}
</style>
