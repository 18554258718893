<template>
  <v-row justify="end">
    <v-col cols="12" md="8">
      <v-row justify="end">
        <v-col
          :cols="$vuetify.breakpoint.xsOnly ? '12' : null"
          v-for="type in listOfCoparativeType"
          :key="type"
        >
          <v-skeleton-loader
            height="100%"
            class="mx-auto"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-for="item in 8" :key="item">
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="list-item"
          ></v-skeleton-loader
        ></v-col>
        <v-col cols="6">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="list-item"
          ></v-skeleton-loader
        ></v-col>
      </v-row>

      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    listOfCoparativeType: {
      type: Array,
      required: true,
    },
  },
}
</script>
