var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"ma-0 pa-0 mt-2",attrs:{"cols":"12","id":"scroll-comparative-table"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"title-fixed ma-0 pa-0"},[_c('v-row',{staticStyle:{"background-color":"white"},attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"7"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.listOfCoparativeType),function(type){return _c('v-col',{key:type},[_c('span',{staticClass:"grey--text text--darken-1 custom-subtitle"},[_vm._v(" "+_vm._s(_vm._f("translate")(type))+" ")])])}),1)],1),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-divider')],1)],1)],1):_vm._e(),_vm._l((_vm.itemsWithComparative),function(item,i){return _c('v-row',{key:i,attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"custom-body-text font-weight-medium"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('v-col',{staticClass:"custom-normal-text grey--text text--darken-1",attrs:{"cols":"12"}},_vm._l((item.activities),function(activity,i){return _c('v-row',{key:i,class:[_vm.$vuetify.breakpoint.mdAndUp ? 'py-1' : 'py-3 px-0'],style:({
          'background-color': i % 2 === 0 ? '#FAFAFA ' : '#E8F5E9',
        })},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('span',{staticClass:"custom-subtitle"},[_vm._v(" "+_vm._s(activity.name)+" ")]),(activity.details)?_c('v-icon',{attrs:{"color":"grey"},on:{"click":function($event){activity['panelActive'] = !activity['panelActive']}}},[_vm._v(" "+_vm._s(!activity['panelActive'] ? 'expand_more' : 'expand_less')+" ")]):_vm._e()],1),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{attrs:{"cols":"3"}}):_vm._e(),_vm._l((_vm.listOfCoparativeType),function(type){return _c('v-col',{key:type,class:[
            _vm.$vuetify.breakpoint.mdAndUp
              ? 'text-center'
              : 'custom-subtitle  ma-0 py-2 px-0',
          ],attrs:{"align-self":"center"}},[(activity.rule[type].type === 'isBlocked')?_c('v-icon',[_vm._v(" remove ")]):_vm._e(),(activity.rule[type].type === 'unlimited')?_c('v-icon',[_vm._v(" check ")]):_vm._e(),(activity.rule[type].type === 'normal')?_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyFromCents")(activity.rule[type].value))+" ")]):_vm._e(),(activity.rule[type].type === 'fixed')?_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.textLimit(activity.rule[type]))+" ")]):_vm._e()],1)}),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(activity.panelActive),expression:"activity.panelActive"}],staticClass:"my-0 py-0 px-5",attrs:{"cols":"12"}},_vm._l((activity.details),function(detail,index){return _c('div',{key:index,class:[
              index !== 0 ? 'mt-1' : '',
              'custom-subtitle-text grey--text text--darken-1',
            ]},[_vm._v(" "+_vm._s(detail)+" ")])}),0)],2)}),1),(_vm.itemsWithComparative.length - 1 !== i)?_c('v-col',{staticClass:"my-2 pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticStyle:{"height":"100%","wight":"100%"}})],1):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }