<template>
  <v-row :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'space-between'">
    <v-col
      :cols="$vuetify.breakpoint.xsOnly ? '12' : null"
      v-for="(plan, index) in localPlans"
      :key="index"
    >
      <v-card elevation="4" height="12rem">
        <v-card-text
          class="text-center d-flex flex-column justify-space-between"
          style="height: 100%"
        >
          <div
            class="
              d-flex
              flex-column
              text-green
              custom-medium-text
              font-weight-bold
            "
          >
            <span
              style="background-color: #c8e6c9; border-radius: 20px"
              class="text-subtitle-2 mx-10"
              v-if="isCurrentPlan(plan.id)"
            >
              Plano Atual
            </span>
            {{ plan.classification | translate }}
          </div>
          <div class="custom-medium-text">
            {{ plan.currentPrice.value | currencyFromCents }}
          </div>
          <v-btn
            class="custom-btn-green rounded-0"
            @click="selectedAPlan(plan.id, plan.valueCents)"
            :loading="getLoadingPlan(plan.id)"
            :disabled="
              (!!loading && !!planLoading.value) ||
              disabled ||
              isCurrentPlan(plan.id)
            "
          >
            contratar
          </v-btn>
          <span
            @click="scrollTo()"
            class="font-weight-bold mt-3 buttun"
            style="cursor: pointer"
            v-if="$vuetify.breakpoint.smAndDown"
          >
            Conheças as vantagens
            <v-icon small color="primary">expand_more</v-icon>
          </span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    plans: {
      type: Array,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idSelectPlan: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      planLoading: {
        id: null,
        value: false,
      },
    }
  },
  computed: {
    localPlans() {
      let tempList = []
      if (this.freePlan) tempList.push(this.freePlan)
      if (this.basicPlan) tempList.push(this.basicPlan)
      if (this.completePlan) tempList.push(this.completePlan)
      return tempList.length > 0 ? tempList : null
    },
    freePlan() {
      return this.plans.find((plan) => plan.classification === 'free')
    },
    basicPlan() {
      return this.plans.find((plan) => plan.classification === 'basic')
    },
    completePlan() {
      return this.plans.find((plan) => plan.classification === 'complete')
    },
  },
  methods: {
    scrollTo() {
      this.$vuetify.goTo('#scroll-comparative-table', {
        duration: 300,
        offset: 0,
        easing: 'linear',
      })
    },
    selectedAPlan(id) {
      this.planLoading.id = id
      this.planLoading.value = true
      this.$emit('selectedAPlan', id)
    },
    getLoadingPlan(id) {
      if (!this.loading) return null
      return this.planLoading.id === id && !!this.planLoading.value
    },
    isCurrentPlan(idPlan) {
      return this.idSelectPlan === idPlan
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
